.radioGrTitle {
    margin: 5px 0px 5px 0px;
}

.trackingOption {
    padding: 9px 20px;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--whitef3);
}

.radioCol {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.input {
    margin: 5px 0px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black85)
}

.input::placeholder {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black45)
}
