html {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Spoqa Han Sans Neo', 'Spoqa Han Sans KR';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: var(--app-height);
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}
::-webkit-scrollbar { display: none; }
code {
  font-family: 'Spoqa Han Sans Neo', 'Spoqa Han Sans KR';
}
