:root {
  --primary: #6E6EFF;
  --lightBlue: #A0A0FF;
  --btnBlue: #5A5AFF;
 --black02: rgba(0,0,0, 0.02);
 --black25: rgba(0, 0, 0, 0.25);
 --black45: rgba(0, 0, 0, 0.45);
 --black85: rgba(0, 0, 0, 0.85);
 --white45: rgba(255, 255, 255, 0.45);
 --white85: rgba(255, 255, 255, 0.85);
  --greyc6: #C6C6C8;
  --greyd9: #d9d9d9;
  --grey92: #929292;
  --grey8c: #8c8c8c;
  --grey3a: #3a3a3a;
  --grey26: #262626;
  --whitef3: #f3f3f3;
  --error: #FF9F90;
  --errorActive: #FF7864;
  --app-height: 100%;
  --nav-height: 44px
}

/*Typography*/
.h2 {
  font-family: Spoqa Han Sans Neo;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-radius: 0 7px 7px 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
  border-radius: 7px 0 0 7px;
}

.ant-input-group-addon {
  border-radius: 7px;
  padding: 0 0px;
  width: 33px;
}

.anticon.ant-input-clear-icon-hidden,
.ant-input-clear-icon-hidden {
  display: inline-block;
  visibility: visible !important;
  background: red !important;
}

.input-number {
  display: inline-block;
  height: 40px;
  width: 120px;
  font-size: 12px;
  line-height: 26px;
  vertical-align: middle;
  border-radius: 7px;
  order: 1;
  flex-grow: 0;
  overflow: hidden;
}

.input-number-input-wrap {
  border: none
}
.input-number-handler-up:hover,
.input-number-handler-up:focus {
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
  border: 1px solid #1890FF;
  box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.2);
  outline: none;
}

.input-number-handler-down:hover,
.input-number-handler-down:focus {
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
  border: 1px solid #1890FF;
  box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.2);
  outline: none;
}

.input-number-input:hover,
.input-number-input:focus {
  border-radius: 0px;
  border: 1px solid #1890FF;
  box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.2);
  outline: none;
}

.input-number-input {
  background: white;
  height: 40px;
  width: 58px;
  text-align: center;
  border: none;
  border-radius: 0px;
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
}

.input-number-focused {
  border: none;
}

.input-number-handler-wrap {
  width: 30px;
}

.input-number-handler-up {
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #FAFAFA;
  padding: 0px;
  gap: 11px;
  height: 40px;
  border: 1px solid #D9D9D9;
  border-radius: 0px 7px 7px 0px;
}

.input-number-handler-down {
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #FAFAFA;
  padding: 0px;
  gap: 11px;
  height: 40px;
  border: 1px solid #D9D9D9;
  border-radius: 7px 0px 0px 7px;
}

.input-number-handler-up-inner
.input-number-handler-down-inner {
  color: var(--black85);
  font-size: 14px;
  line-height: 36px;
}

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.subtitle {
  font-size: 18px;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.subtitle2 {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
}

.body1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--grey26);
}

.body2 {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.body {
  color: var(--black85);
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.small1 {
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  text-align: left;
}

.small {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.black {
  font-weight: 900;
}

.bold {
  font-weight: 700 !important;
}

.medium {
  font-weight: 500;
}

.regular {
  font-weight: 400;
}

.text-center {
  text-align: center;
}

.grey92 {
  color: var(--grey92);
}

.primary {
  color: var(--primary);
}

.white {
  color: white;
}

.white85 {
  color: var(--white85);
}

.black45 {
  color: var(--black45);
}

.black85 {
  color: var(--black85);
}

.flex {
  flex: 1
}

.align-center {
  align-items: center;
}

.nowrap {
  white-space: nowrap;
}

.preline {
  white-space: pre-line !important;
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000000;
  color: white;
  padding: 8px;
  z-index: 100;
}

/*common styles*/
.container {
  width: 100vw;
  min-height: var(--app-height);
  background-color: var(--whitef3);
  display: flex;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--primary);
}

.ant-select-dropdown-lg {
  height: 80px;
  border: 1px solid var(--primary);
  border-radius: 7px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 7px;
  height: 40px;
  width: 100%;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.ant-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px solid var(--btnBlue);
  width: 100%;
  height: 32px;
  border-radius: 7px;
  color: var(--btnBlue);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
}

.ant-btn-primary {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  height: 60px;
  background: var(--primary);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 14px;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: var(--primary);
}

.inner-container {
  width: 100%;
  display: flex;
  padding-top: var(--nav-height);
  margin-left: auto;
  margin-right: auto;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}

.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--primary);
}
.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary);
}

.ant-radio-inner::after {
  background-color: var(--primary);
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  margin-right: 4px;
  margin-left: 4px;
  border-radius: 6px;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: var(--primary);
  background-color: #F2EFFF;
  font-weight: 700;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  color: var(--primary);
  background-color: #F2EFFF;
  font-weight: 700;
}

.ant-input {
  background: #FFFFFF;
  border: 1px solid var(--primary);
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5);
  border-radius: 7px;
  flex: none;
  height: 40px;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--grey26);
}

.ant-input:hover,
.ant-input:focus,
.ant-input-focused {
  border-color: var(--primary);
}

.ant-input::placeholder {
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--grey8c);
}

.ant-input-affix-wrapper {
  display: inline-flex;
  align-items: center;
  height: 40px !important;
  border-radius: 7px;
}

.ant-input-affix-wrapper .ant-input {
  height: 40px !important;
  align-items: center;
  background: transparent;
  margin-left: 10px;
  border-color: transparent;
  box-shadow: none;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--primary);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus {
  border-color: var(--primary);
}

.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: var(--primary);
}

.ant-input:disabled {
  background-color: var(--black25);
}

.ant-message {
  position: fixed;
  bottom: 120px !important;
  top: unset !important;
  color: white;
}

.ant-message-notice-content {
  background-color: var(--grey26);
  border-radius: 7px;
}

/*modal custom design*/
.ant-modal-footer {
  border-top: 1px solid var(--grey3a);
  display: flex;
  height: 53px;
  flex-direction: row;
  align-items: center;
}

.ant-modal-content {
  background: rgba(34, 34, 34, 0.9);
  backdrop-filter: blur(48px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 21.3869px;
  white-space: pre-line;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 30px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-modal-confirm {
  max-width: 295px;
}

.ant-modal-confirm-btns .ant-btn{
  float: right;
  margin-left: 10px;
}


.ant-modal-confirm .ant-modal-body {
  padding: 20px 0px 0px 0px;
  background: rgba(34,34,34, 0.9);
  filter: drop-shadow(-11.0895px 10.2974px 36.437px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(224px);
  border-radius: 21px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-weight: 700;
  color: white;
  text-align: center;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin: 0px;
  padding: 12px 20px;
  font-size: 14px;
  color: white;
  text-align: center;
  width: 295px;
  border-bottom: 1px solid var(--black45);
}

.ant-modal-confirm-body > .anticon {
  transform: scale(0);
  width: 0px;
  padding: 0px;
  margin: 0px
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 0px;
}


.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  flex: 1;
  width: 100%;
  margin: 0px;
  color: var(--primary);
}

.ant-modal-confirm-btns .ant-btn{
  height: 50px;
  border: none;
  background: none;
  margin: 0px;
  color: var(--primary)
}

.ant-modal-confirm .ant-modal-confirm-btns {
  width: 100%;
  margin: 0px;
  display: flex;
  color: var(--primary);
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn  {
  color: white;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  flex: 1;
}

ant-modal-footer {
  padding: 0px 20px 20px 20px;
  text-align: right;
  background: transparent;
  border-radius: 0 0 2px 2px;
  margin: 0px;
  border: none;
}

.icon:hover {
  fill: #DA4567;
}

.row {
  display: flex;
  flex-direction: row;
}

.space-between {
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--primary);
}

.ant-radio-checked::after {
  border: 1px solid var(--primary);
}

.ant-radio-inner::after {
  background-color: var(--primary);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primary);
}
.ant-checkbox-checked::after {
  border: 1px solid var(--primary);
}

.round-cntr {
  background: white;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  border-radius: 14px;
  box-shadow: inset 0px 1px 0px #F0F0F0;
  overflow: hidden;
  padding: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
