.tracking-container {
    padding: 20px;
    padding-bottom: 100px;
}

.navbar-location {
    background: var(--primary)!important;
}

.navbar-title-location {
    color: white!important;
}

.option-cntr {
    margin: 20px 20px 0px;
    background: white;
    border-radius: 7px;
    border: 1px solid #6766EE;
    box-shadow: 0px 0px 4px 0px rgba(24, 144, 255, 0.50);
    box-sizing: border-box;
    height: 38px;
    display: flex;align-items: center;
    padding: 8px 12px;
}

.option-menu {
    background: white;
    border-radius: 7px;
}

.option-item {
    height: 32px;
    display: flex;
    align-items: center;
}

.option-item.body:hover,
.option-item.body:focus {
    color: var(--primary) !important;
}

.tracking-sts {
    background: var(--primary);
    border-radius: 18px;
    padding: 5px 10px;
    margin-top: 14px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    min-width: 50px;
}

.tracking-info {
    margin-top: 2px;
    margin-bottom: 7px;
}

.tracking-title {
    margin-top: 20px;
    margin-bottom: 10px;
    color: var(--black85);
}

.tracking-label {
    background: rgba(0, 0, 0, 0.06);
    width: 84px;
    display: flex;
    padding: 10px;
    align-items: center;
}

.tracking-val {
    background: rgba(255, 255, 255, 1);
    display: flex;
    flex: 1;
    align-items: center;
    padding: 10px;
    box-shadow: inset 0px 1px 0px #F0F0F0;
}

.tracking-btn-detail {
    box-shadow: inset 1px 1px 1px #F0F0F0;
    border-radius: 0px;
    border: none;
    height: 40px;
}

.tracking-panel {
    display: none;
}

.tracking-panel-active {
    display: initial;
    transition: height 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.tracking-btn-txt {
    color: var(--black45);
    text-decoration: underline;
    margin-right: 5.75px;
}

.tracking-btn-txt:hover {
    color: var(--black25);
}

.tracking-del {
    box-shadow: inset 0px 1px 0px #F0F0F0;
}

.tracking-del-date {
    flex: 3;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    box-shadow: inset -1px 0px 0px #F0F0F0;
}

.tracking-del-sts {
    padding-top: 10px;
    padding-bottom: 12px;
    padding-left: 14px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1;
}

.tracking-sts-lng {
    white-space: pre-line;
}

.tracking-btn {
    width: 20px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: none;
}

.tracking-del-img {
    width: 225px;
    object-fit: contain;
    background: var(--greyc6);
}

.tracking-return-btn {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: calc(100% - 40px);
    z-index: 10;
}
