

.input {
    width: 120px;
    height: 33px;
    text-align: center;
    padding: 0px;
}

.inputUp {
    width: 33px !important;
    height: 33px !important;
    padding: 10px;
}

.inputDown {
    width: 100%;
    height: 100%;
    padding: 10px;
}


.inputDown:focus,
.inputUp:focus,
.inputDown:hover,
.inputUp:hover {
    /*border: 1px solid #D9D9D9;*/
}
