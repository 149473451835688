.orderContainer {
    border-radius: 14px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    overflow: hidden;
    margin-top: 5px;
}

.radio {
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
    width: 100%;
}

.title {
    margin-bottom: 5px;
}

.input {
    margin-left: auto;
    margin-top: 4px;
}

.inputReason {
    margin-top: 5px;
    height: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.inputReason::placeholder {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black45)
}
.done {
    color: var(--black85)
}

.active {
    color: var(--black85)
}

.passive {
    color: var(--black45)
}

.stepactive {
    color: var(--black85) !important;
}

.stepdone {
    color: var(--black45) !important;
}

.steppassive {
    color: var(--black45) !important;
}

.check {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 32px;
    text-align: center;
}

.checkIcon {
    width: 12px;
    height: 12px;
}

.check.active {
    color: white;
    border: 1px solid var(--primary);
    background: var(--primary);
}

.check.done {
    color: var(--black45);
    border: 1px solid var(--primary);
}

.check.passive {
    color: var(--black45);
    border: 1px solid var(--black45);
}

.container {
    padding: 16px;
    background: #FFF;
}

.order {
    color: black;
}

.option {
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.50);
}
