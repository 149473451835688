.notfound-cntr {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.notfound-404 {
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0px;
    text-align: center;
}

.notfound-txt {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
}
