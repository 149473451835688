.inner {
    padding: 20px;
}

.roundCntr {
    background: white;
    border: 0.5px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 14px;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    overflow: hidden;
}

.checkCntr {
    padding: 10px;
    margin-bottom: 80px;
}

.btnSave {
    position: fixed;
    bottom: 44px;
    width: calc( 100% - 40px);
}

.infoContainer {
    padding: 15px 40px;
    text-align: center;
}

.label {
    margin-top: 5px;
}
