.inner {
    padding: 20px;
}

.searchInner {
    padding: 62px 20px 0px;
}

.roundCntr {
    background: white;
    border: 0.5px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 14px;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    overflow: hidden;
}

.roundInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 102px;
}

.radioGrTitle {
    margin: 5px 0px 5px 0px;
}

.checkCntr {
    padding: 10px;
    margin-bottom: 80px;
}

.btnSave {
    position: fixed;
    bottom: 44px;
    width: calc( 100% - 40px);
}

.infoContainer {
    padding: 15px 40px;
    text-align: center;
}

.trackingOption {
    padding: 9px 20px;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--whitef3);
}

.radioCol {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.trackingOther {
    padding: 15px 0px 0px 20px;
    display: flex;
    align-items: center;
    width: 100%;
}

.radioGrp {
    display: flex;
    flex-direction: column;
}

.input {
    margin: 5px 0px;
}

.inputSearch {
    height: 60px;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 14px;
    flex: 1;
}

.inputCode {
    margin: 5px 5px 0px 0px;
    flex: 1;
}

.inputPass {
    margin: 5px 0px;
    flex: 2;
}
